import { Divider, Form, Input, Radio, Space, Button, Checkbox } from 'antd';
import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { ArrowRightOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { ConsentLink } from '../../ConsentLink';
import { SignupFormBodyStep1, FORM_FIELD_VALUES_ENUM } from '../types';

const FormLabel = styled.p`
  font-size: 24px;
`;

type Props = {
  handleNextStep: (data: SignupFormBodyStep1) => void;
  isOrganization?: boolean;
};

export const SignupFormStep1 = ({
  handleNextStep,
  isOrganization = true,
}: Props) => {
  const [formStep1] = Form.useForm<SignupFormBodyStep1>();
  const formWatchBehalf = Form.useWatch<string>(
    FORM_FIELD_VALUES_ENUM.IS_DEPENDANT,
    formStep1,
  );

  const renderStep1AdditionalInfo = useCallback(() => {
    if (formWatchBehalf) {
      return (
        <div
          style={{ backgroundColor: '#F3CF98' }}
          className="mt-5 p-6 rounded-xl shadow-lg mb-10"
        >
          <div>
            Remember to only provide the information of the person who will take
            part in the program. Including their name, email, and date of birth.
          </div>
        </div>
      );
    }
    return null;
  }, [formWatchBehalf]);

  const renderApplyingFor = useCallback(() => {
    if (!isOrganization) return null;
    return (
      <>
        <Form.Item
          // initialValue={''}
          name={FORM_FIELD_VALUES_ENUM.IS_DEPENDANT}
          label={<FormLabel>I am applying for</FormLabel>}
          valuePropName="value"
          rules={[
            {
              required: true,
              message: 'Please tell us whom you are applying for.',
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={false}>Myself</Radio>
              <Radio value>A partner or dependent</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {renderStep1AdditionalInfo()}
      </>
    );
  }, [isOrganization]);

  return (
    <>
      <Form
        wrapperCol={{ span: 12 }}
        size="large"
        name="step1"
        layout="vertical"
        key="step1-form"
        form={formStep1}
        onFinish={async v => {
          await formStep1.validateFields();
          handleNextStep(formStep1.getFieldsValue());
        }}
      >
        <div className="text-2xl font-bold">Let’s start your enrollment</div>
        <div className="pb-6">
          {`We’ll need to ask for personal health information, so let's create an
          account to keep it secure.`}
        </div>
        <div className="pb-6">Already have an account? Sign in</div>
        {renderApplyingFor()}

        {/* <div className=" text-2xl font-bold"> */}
        {/*  {"Let's set up your Bastion Health account"} */}
        {/* </div> */}

        <Divider />
        <Form.Item
          rules={[{ type: 'email' }]}
          name={FORM_FIELD_VALUES_ENUM.EMAIL}
          required
          label="Email Address"
        >
          <Input
            css={css`
              border: 1px solid rgb(212, 212, 212);
            `}
          />
        </Form.Item>
        <Form.Item
          name={FORM_FIELD_VALUES_ENUM.PASSWORD}
          required
          label="Create Password"
          rules={[
            { min: 8, message: 'Password must be at least 8 characters.' },
            { max: 20, message: 'Password must be at most 20 characters.' },
            { pattern: /\d/, message: 'Password must contain a number.' },
            {
              pattern: /[A-Z]/,
              message: 'Password must contain an uppercase letter.',
            },
            {
              pattern: /[a-z]/,
              message: 'Password must contain a lowercase letter.',
            },
            { required: true, message: 'Please input your password!' },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name={FORM_FIELD_VALUES_ENUM.AGREEMENT}
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error('Should accept agreement')),
            },
          ]}
        >
          <Checkbox>
            I accept{' '}
            <ConsentLink
              target="_blank"
              href="https://getbastion.com/online-terms-of-use"
            >
              Online Terms of use
            </ConsentLink>
            {', '}
            <ConsentLink
              target="_blank"
              href="https://getbastion.com/online-privacy-policy"
            >
              Online Privacy Policy
            </ConsentLink>
            {' & '}
            <ConsentLink
              target="_blank"
              href="https://getbastion.com/notice-of-privacy-practices"
            >
              Notice of Privacy Practices
            </ConsentLink>
            {' & '}
            <ConsentLink
              target="_blank"
              href="https://getbastion.com/telehealth-informed-consent"
            >
              Telehealth Informed Consent
            </ConsentLink>
          </Checkbox>
        </Form.Item>

        <Button
          // onClick={handleNextStep}
          htmlType="submit"
          icon={<ArrowRightOutlined />}
          type="primary"
        >
          Create Account
        </Button>
      </Form>
    </>
  );
};
