export const hormonalHealthDisclaimerStates = [
  { value: 'Alabama', abbr: 'AL' },
  { value: 'American Samoa', abbr: 'AS' },
  { value: 'Arkansas', abbr: 'AR' },
  { value: 'Connecticut', abbr: 'CT' },
  { value: 'Delaware', abbr: 'DE' },
  { value: 'District Of Columbia', abbr: 'DC' },
  { value: 'Federated States Of Micronesia', abbr: 'FM' },
  { value: 'Georgia', abbr: 'GA' },
  { value: 'Guam', abbr: 'GU' },
  { value: 'Hawaii', abbr: 'HI' },
  { value: 'Louisiana', abbr: 'LA' },
  { value: 'Marshall Islands', abbr: 'MH' },
  { value: 'Minnesota', abbr: 'MN' },
  { value: 'Missouri', abbr: 'MO' },
  { value: 'New Hampshire', abbr: 'NH' },
  { value: 'New York', abbr: 'NY' },
  { value: 'North Dakota', abbr: 'ND' },
  { value: 'Northern Mariana Islands', abbr: 'MP' },
  { value: 'Palau', abbr: 'PW' },
  { value: 'Pennsylvania', abbr: 'PA' },
  { value: 'Puerto Rico', abbr: 'PR' },
  { value: 'Rhode Island', abbr: 'RI' },
  { value: 'South Carolina', abbr: 'SC' },
  { value: 'South Dakota', abbr: 'SD' },
  { value: 'Tennessee', abbr: 'TN' },
  { value: 'Virgin Islands', abbr: 'VI' },
  { value: 'West Virginia', abbr: 'WV' },
];
