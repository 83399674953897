import {
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  Layout,
  Radio,
  Select,
  Space,
  InputNumber,
  Steps,
  Button,
  Result,
  Checkbox,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ArrowRightOutlined, RightOutlined } from '@ant-design/icons';
import { find } from 'lodash';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { convertToIsoFormat } from 'utils/date';
import { images } from '../../constants';
import { NotAvailableStateModal } from '../../components/NotAvailableStateModal';
import {
  useEmployeePublicDetails,
  useEmployeeRegistration,
} from '../../hooks/Api/employee';

import {
  FORM_FIELD_VALUES_ENUM,
  SignupFormStep1,
  SignupFormStep2,
} from './types';

import { EmployeeSignupForm1 } from './EmployeeSignup.FormStep1';
import { EmployeeSignupForm2 } from './EmployeeSignup.FormStep2';
import { useRegisterProspectEmployee } from '../../hooks/Api/prospect';
import { SignupErrorModal } from '../../components/SignupErrorModal';

const { Header, Footer, Content } = Layout;

type Step = {
  key: number;
  title: string;
  bang: string;
};

const STEPS = [
  {
    key: 0,
    title: 'Getting Started',
    // description: 'Start setting-up your account',
    bang: 'get-started',
  },
  {
    key: 1,
    title: 'Basic Information',
    // description: 'Let us know you better',
    bang: 'basic-information',
  },
  {
    key: 2,
    title: 'Completed',
    // description: 'You are ready to get our services',
    bang: 'completed',
  },
];

type ParamsProps = {
  employerCode: string;
};

export const EmployeeSignup = () => {
  const { employerCode = '' } = useParams<ParamsProps>();
  const navigate = useNavigate();
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const {
    data: employerData,
    isLoading: employerDataIsLoading,
    error,
  } = useEmployeePublicDetails(employerCode);

  /* Register prospect */
  const { registerProspectEmployee } = useRegisterProspectEmployee();

  const [createUserPayload, setCreateUserPayload] = useState<SignupFormStep1>(
    {},
  );
  const [formStep2] = Form.useForm<SignupFormStep2>();
  const [formStep1] = Form.useForm<SignupFormStep1>();

  /* Local states */
  const [currentStep, setCurrentStep] = useState<Step>(STEPS[0]);
  const [selectedState, selectState] = useState<string>('');

  const [notAvailableModal, setNotAvailableModal] = useState<boolean>(false);

  /* Out of service zone */
  const [outOfService, setOutOfService] = useState(false);

  /* Apis */
  const {
    registerEmployee,
    isLoading,
    isSuccess,
    error: registerEmployeeError,
  } = useEmployeeRegistration();

  useEffect(() => {
    if (error) {
      navigate('/employer-not-found');
    }
  }, [error]);

  useEffect(() => {
    if (employerData) {
      setTimeout(() => {
        window.parent.postMessage(document.body.scrollHeight, '*');
      }, 300); // Keeping this timeout as we would like to wait for the form to render
    }
  }, [employerData, currentStep]);

  useEffect(() => {
    function sendHeight() {
      const height = document.body.scrollHeight;
      window.parent.postMessage(height, '*');
    }

    window.addEventListener('load', sendHeight);
    window.addEventListener('resize', sendHeight);
  }, []);

  const renderHeader = useCallback(() => {
    return (
      <div className="rounded-2xl justify-items-center items-center flex flex-col">
        {employerData?.jointLogo ? (
          <Image
            className="self-center justify-self-center max-w-sm max-h-48"
            src={employerData.jointLogo}
            preview={false}
          />
        ) : null}
      </div>
    );
  }, [employerData, employerDataIsLoading]);
  const handleNextStep = useCallback(() => {
    setCurrentStep(pv => find(STEPS, { key: pv.key + 1 }) || pv);
  }, [setCurrentStep]);

  const handleSubmitForm = useCallback(() => {
    const form2Values = formStep2.getFieldsValue();
    form2Values[FORM_FIELD_VALUES_ENUM.PHONE] = `+1${
      form2Values[FORM_FIELD_VALUES_ENUM.PHONE]
    }`;
    form2Values[FORM_FIELD_VALUES_ENUM.DOB] = convertToIsoFormat(
      form2Values[FORM_FIELD_VALUES_ENUM.DOB],
    );
    registerEmployee({
      ...createUserPayload,
      ...form2Values,
      employerCode,
    }).catch(e => {
      setIsErrorModalVisible(true);
    });
  }, [createUserPayload, formStep2, employerCode]);

  useEffect(() => {
    if (isSuccess) {
      handleNextStep();
    }
  }, [handleNextStep, isSuccess]);

  const renderStep1 = useCallback(() => {
    return (
      <EmployeeSignupForm1
        form={formStep1}
        handleNextStep={data => {
          setCreateUserPayload(pv => ({ ...pv, ...data }));
          /* Register prospect */
          try {
            registerProspectEmployee({
              email: data[FORM_FIELD_VALUES_ENUM.EMAIL],
              isDependent: data[FORM_FIELD_VALUES_ENUM.IS_DEPENDANT],
              employerCode,
            });
          } catch (e) {
            /* Do nothing */
          }
          handleNextStep();
        }}
      />
    );
  }, [setCreateUserPayload, handleNextStep, employerCode]);
  const renderStep2 = useCallback(() => {
    return (
      <EmployeeSignupForm2
        onStateSelect={selectState}
        form={formStep2}
        isLoading={isLoading}
        setOutOfService={setOutOfService}
        onSubmit={handleSubmitForm}
      />
    );
  }, [formStep2, handleSubmitForm, isLoading, setOutOfService, selectState]);

  const renderStep3 = useCallback(() => {
    return (
      <div className="items-center justify-center flex flex-1 flex-col">
        <Result status="success" title="Registration Completed " />
        {!outOfService && (
          <Button
            onClick={() => {
              window.location.href =
                'https://www.getbastion.com/plan-activation-steps';
            }}
            icon={<RightOutlined />}
            type="primary"
            className="align-middle justify-self-center self-center center-block mb-20"
          >
            {" Let's get started "}
          </Button>
        )}
        {outOfService && (
          <div className="p-10 text-center center-block bg-gray-300 rounded border-gray-400 border-2">
            {`
            Thank you for your interest in Bastion Health! We're currently expanding our services, and your state is on our radar.
            Stay tuned for updates - we'll notify you as soon as we're available in your area. Exciting times ahead!
            `}
          </div>
        )}
      </div>
    );
  }, [outOfService, selectedState]);

  const renderStepContent = useCallback(() => {
    const { key } = currentStep;
    switch (key) {
      case 0:
        return renderStep1();
      case 1:
        return renderStep2();
      case 2:
        return renderStep3();
      default:
        return renderStep1();
    }
  }, [currentStep, renderStep1, renderStep2, renderStep3]);

  const handleRedactSensitiveData = useCallback(() => {
    formStep1.resetFields([
      FORM_FIELD_VALUES_ENUM.PASSWORD,
      FORM_FIELD_VALUES_ENUM.AGREEMENT,
    ]);
  }, []);

  const renderContent = useCallback(() => {
    if (employerDataIsLoading) {
      return null;
    }
    return (
      <div className="rounded-2xl bg-white w-full p-6 ">
        <Space className="w-full" size={20} direction="vertical">
          <div className="text-center text-2xl">
            {
              'Bastion Health is your confidential specialty digital men’s health clinic available at your convenience.'
            }
          </div>
          <Steps
            direction="horizontal"
            size="default"
            current={currentStep.key}
            items={STEPS}
            onChange={value => {
              if (value === 0) {
                setCurrentStep(find(STEPS, { key: value }) || currentStep);
                handleRedactSensitiveData();
              }
            }}
          />
          <div className="">{renderStepContent()}</div>
        </Space>
      </div>
    );
  }, [
    currentStep,
    renderStepContent,
    employerDataIsLoading,
    handleRedactSensitiveData,
  ]);

  return (
    <div className="bg-gray-100">
      <Layout className="px-4 sm:px-12 pb-12">
        {renderHeader()}

        <Content>{renderContent()}</Content>
      </Layout>
      <NotAvailableStateModal
        selectedState={selectedState}
        visible={notAvailableModal}
        ailment=""
        onClose={() => {
          selectState('');
          setNotAvailableModal(false);
        }}
      />
      <SignupErrorModal
        visible={isErrorModalVisible}
        error={registerEmployeeError}
        onClose={() => {
          setIsErrorModalVisible(false);
        }}
      />
    </div>
  );
};
