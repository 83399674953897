export enum QueryNames {
  GetStripeClientSecret = 'GetStripeClientSecret',
  GetStripeDirectPaymentSessionId = 'GetStripeDirectPaymentSessionId',
  CalculatePlanPrice = 'CalculatePlanPrice',
  GetStripeProduct = 'GetStripeProduct',
  GetAllStripeProduct = 'GetAllStripeProduct',
  GetAllPackages = 'GetAllPackages',
  ApplyCoupon = 'ApplyCoupon',
  RegisterProspect = 'RegisterProspect',
  RegisterProspectEmployee = 'RegisterProspectEmployee',
  RegisterEmployee = 'RegisterEmployee',
  GetAllDepartments = 'GetAllDepartments',
}
