export enum FORM_FIELD_VALUES_ENUM {
  IS_DEPENDANT = 'isDependent',
  ON_BEHALF = 'onBehalf',
  EMAIL = 'email',
  AGREEMENT = 'agreement',
  FIRSTNAME = 'firstName',
  NURSE_NOTES = 'nurseNotes',
  LASTNAME = 'lastName',
  MIDDLE_NAME = 'middle-name',
  STATE = 'state',
  DOB = 'birthday',
  PHONE = 'phone',
  PACKAGE_NAME = 'packageIds',
  ZIP = 'zip',
}
export type SignupFormStep1 = {
  [FORM_FIELD_VALUES_ENUM.ON_BEHALF]: 'partner' | 'myself';
  [FORM_FIELD_VALUES_ENUM.IS_DEPENDANT]: boolean;
  [FORM_FIELD_VALUES_ENUM.EMAIL]: string;
  [FORM_FIELD_VALUES_ENUM.AGREEMENT]: boolean;

  [FORM_FIELD_VALUES_ENUM.FIRSTNAME]: string;
  [FORM_FIELD_VALUES_ENUM.LASTNAME]: string;
  [FORM_FIELD_VALUES_ENUM.MIDDLE_NAME]: string;
  [FORM_FIELD_VALUES_ENUM.STATE]: string;
  [FORM_FIELD_VALUES_ENUM.DOB]: string;
  [FORM_FIELD_VALUES_ENUM.PHONE]: string;
  [FORM_FIELD_VALUES_ENUM.PACKAGE_NAME]: string[];
};

export type SignupFormStep2 = {
  [FORM_FIELD_VALUES_ENUM.FIRSTNAME]: string;
  [FORM_FIELD_VALUES_ENUM.LASTNAME]: string;
  [FORM_FIELD_VALUES_ENUM.MIDDLE_NAME]: string;
  [FORM_FIELD_VALUES_ENUM.STATE]: string;
  [FORM_FIELD_VALUES_ENUM.DOB]: string;
  [FORM_FIELD_VALUES_ENUM.PHONE]: string;
};
