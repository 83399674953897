import {
  Form,
  Image,
  Layout,
  Radio,
  Space,
  InputNumber,
  Steps,
  Button,
  Result,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { find, isEmpty } from 'lodash';
import moment from 'moment';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { convertToIsoFormat } from 'utils/date';
import { NotAvailableStateModal } from 'components/NotAvailableStateModal';

import {
  FORM_FIELD_VALUES_ENUM,
  SignupFormBodyStep1,
  SignupFormBodyStep2,
} from 'components/Registration/types';

import { SignupFormStep1 } from 'components/Registration/steps/SignupFormStep1';
import { SignupFormStep2 } from 'components/Registration/steps/SignupFormStep2';
import { RightOutlined } from '@ant-design/icons';
import { loadStripe } from '@stripe/stripe-js';
import { useRegisterProspectEmployee } from '../../hooks/Api/prospect';
import { useEmployeeRegistration } from '../../hooks/Api/employee';
import { images } from '../../constants';
import { useQueryParams } from '../../utils/url';
import { useGetStripDirectPaymentSessionId } from '../../hooks/Api/stripe';

const { Header, Footer, Content } = Layout;
const { REACT_APP_STRIPE_PK } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PK || '');

type Step = {
  key: number;
  title: string;
  bang: string;
};

const STEPS = [
  {
    key: 0,
    title: 'Getting Started',
    // description: 'Start setting-up your account',
    bang: 'get-started',
  },
  {
    key: 1,
    title: 'Basic Information',
    // description: 'Let us know you better',
    bang: 'basic-information',
  },
  {
    key: 2,
    title: 'Completed',
    // description: 'You are ready to get our services',
    bang: 'completed',
  },
];

type ParamsProps = {
  employerCode: string;
};

export const Signup = () => {
  const navigate = useNavigate();
  const packageIds = useQueryParams('packageId')
    ?.split(',')
    .filter(item => !isEmpty(item));
  /* Register prospect */
  const { registerProspectEmployee } = useRegisterProspectEmployee();
  const { mutate: getSessionId, data: stripeSessionId } =
    useGetStripDirectPaymentSessionId();

  const [createUserPayload, setCreateUserPayload] =
    useState<SignupFormBodyStep1>({});
  const [formStep2] = Form.useForm<SignupFormBodyStep2>();

  /* Local states */
  const [currentStep, setCurrentStep] = useState<Step>(STEPS[0]);
  const [selectedState, selectState] = useState<string>('');

  const [notAvailableModal, setNotAvailableModal] = useState<boolean>(false);

  /* Out of service zone */
  const [outOfService, setOutOfService] = useState(false);

  /* Apis */
  const { registerEmployee, isLoading, isSuccess } = useEmployeeRegistration();

  useEffect(() => {
    setTimeout(() => {
      window.parent.postMessage(window.outerHeight, '*');
    }, 200); // Keeping this timeout as we would like to wait for the form to render
  }, [currentStep]);

  useEffect(() => {
    function sendHeight() {
      const height = window.outerHeight;
      window.parent.postMessage(height, '*');
    }

    window.addEventListener('load', sendHeight);
    window.addEventListener('resize', sendHeight);
  }, []);

  useEffect(() => {
    formStep2.setFieldValue(FORM_FIELD_VALUES_ENUM.PACKAGE_NAME, packageIds);
  }, [packageIds]);

  useEffect(() => {
    const handleStripe = async (sessionId: string) => {
      const stripe = await stripePromise;
      await stripe?.redirectToCheckout({
        sessionId,
      });
    };
    if (stripeSessionId) {
      handleStripe(stripeSessionId);
    }
  }, [stripeSessionId]);
  const renderHeader = useCallback(() => {
    return (
      <div className="rounded-2xl justify-items-center items-center flex flex-col py-10">
        <Image
          className="self-center justify-self-center "
          src={images.bastionLogo}
          height={100}
          preview={false}
        />
      </div>
    );
  }, []);
  const handleNextStep = useCallback(() => {
    setCurrentStep(pv => find(STEPS, { key: pv.key + 1 }) || pv);
  }, [setCurrentStep]);

  const handleSubmitForm = useCallback(() => {
    const form2Values = formStep2.getFieldsValue();
    form2Values[FORM_FIELD_VALUES_ENUM.PHONE] = `+1${
      form2Values[FORM_FIELD_VALUES_ENUM.PHONE]
    }`;
    form2Values[FORM_FIELD_VALUES_ENUM.DOB] = convertToIsoFormat(
      form2Values[FORM_FIELD_VALUES_ENUM.DOB],
    );
    getSessionId({
      ...createUserPayload,
      ...form2Values,
    });
  }, [createUserPayload, formStep2]);

  useEffect(() => {
    if (isSuccess) {
      handleNextStep();
    }
  }, [handleNextStep, isSuccess]);

  const renderStep1 = useCallback(() => {
    return (
      <SignupFormStep1
        isOrganization={false}
        handleNextStep={data => {
          setCreateUserPayload(pv => ({ ...pv, ...data }));
          /* Register prospect */
          try {
            registerProspectEmployee({
              email: data[FORM_FIELD_VALUES_ENUM.EMAIL],
              isDependent: data[FORM_FIELD_VALUES_ENUM.IS_DEPENDANT],
            });
          } catch (e) {
            /* Do nothing */
          }
          handleNextStep();
        }}
      />
    );
  }, [setCreateUserPayload, handleNextStep]);
  const renderStep2 = useCallback(() => {
    return (
      <SignupFormStep2
        onStateSelect={selectState}
        form={formStep2}
        isLoading={isLoading}
        setOutOfService={setOutOfService}
        onSubmit={handleSubmitForm}
        defaultSelectedPackages={packageIds}
      />
    );
  }, [
    formStep2,
    handleSubmitForm,
    isLoading,
    setOutOfService,
    selectState,
    packageIds,
  ]);

  const renderStep3 = useCallback(() => {
    return (
      <div className="items-center justify-center flex flex-1 flex-col">
        <Result status="success" title="Registration Completed " />
        {!outOfService && (
          <Button
            onClick={() => {
              window.location.href =
                'https://www.getbastion.com/plan-activation-steps';
            }}
            icon={<RightOutlined />}
            type="primary"
            className="align-middle justify-self-center self-center center-block mb-20"
          >
            {" Let's get started "}
          </Button>
        )}
        {outOfService && (
          <div className="p-10 text-center center-block bg-gray-300 rounded border-gray-400 border-2">
            {`
            Thank you for your interest in Bastion Health! We're currently expanding our services, and your state is on our radar.
            Stay tuned for updates - we'll notify you as soon as we're available in your area. Exciting times ahead!
            `}
          </div>
        )}
      </div>
    );
  }, [outOfService, selectedState]);

  const renderStepContent = useCallback(() => {
    const { key } = currentStep;
    switch (key) {
      case 0:
        return renderStep1();
      case 1:
        return renderStep2();
      case 2:
        return renderStep3();
      default:
        return renderStep1();
    }
  }, [currentStep, renderStep1, renderStep2, renderStep3]);

  const renderContent = useCallback(() => {
    return (
      <div className="rounded-2xl bg-white w-full p-6 ">
        <Space className="w-full" size={20} direction="vertical">
          <div className="text-center text-2xl">
            {
              'Bastion Health is your confidential specialty digital men’s health clinic available at your convenience.'
            }
          </div>
          <Steps
            direction="horizontal"
            size="default"
            current={currentStep.key}
            items={STEPS}
          />
          <div className="">{renderStepContent()}</div>
        </Space>
      </div>
    );
  }, [currentStep, renderStepContent]);

  return (
    <div className="h-screen bg-gray-100 ">
      <Layout className="px-4 sm:px-12 pb-12">
        {renderHeader()}
        <Content>{renderContent()}</Content>
      </Layout>
      <NotAvailableStateModal
        selectedState={selectedState}
        visible={outOfService}
        ailment=""
        onClose={() => {
          selectState('');
          setOutOfService(false);
        }}
      />
    </div>
  );
};
