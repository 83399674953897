import {
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  Layout,
  Radio,
  Select,
  Space,
  InputNumber,
  Steps,
  Button,
  Result,
  Checkbox,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ArrowRightOutlined, RightOutlined } from '@ant-design/icons';
import { find } from 'lodash';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { convertToIsoFormat } from 'utils/date';
import { StateName } from '../../utils/States';
import { images } from '../../constants';
import { NotAvailableStateModal } from '../../components/NotAvailableStateModal';
import {
  useEmployeePublicDetails,
  useEmployeeRegistration,
} from '../../hooks/Api/employee';

import { useRegisterProspectEmployee } from '../../hooks/Api/prospect';
import { NursePartnerSignupFormStep1 } from './NursePartnerSignup.FormStep1';

const { Header, Footer, Content } = Layout;

type Step = {
  key: number;
  title: string;
  bang: string;
};

const STEPS = [
  {
    key: 0,
    title: 'Getting Started',
    // description: 'Start setting-up your account',
    bang: 'get-started',
  },
  {
    key: 1,
    title: 'Basic Information',
    // description: 'Let us know you better',
    bang: 'basic-information',
  },
  {
    key: 2,
    title: 'Completed',
    // description: 'You are ready to get our services',
    bang: 'completed',
  },
];

type ParamsProps = {
  employerCode: string;
};

export const NursePartnerSignup = () => {
  const { employerCode = '' } = useParams<ParamsProps>();
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const {
    data: employerData,
    isLoading: employerDataIsLoading,
    error,
  } = useEmployeePublicDetails(employerCode);

  /* Register prospect */
  const { registerProspectEmployee } = useRegisterProspectEmployee();

  useEffect(() => {
    if (error) {
      navigate('/employer-not-found');
    }
  }, [error]);

  useEffect(() => {
    if (employerData) {
      setTimeout(() => {
        window.parent.postMessage(document.body.scrollHeight, '*');
      }, 100); // Keeping this timeout as we would like to wait for the form to render
    }
  }, [employerData]);

  useEffect(() => {
    function sendHeight() {
      const height = document.body.scrollHeight;
      window.parent.postMessage(height, '*');
    }

    window.addEventListener('load', sendHeight);
    window.addEventListener('resize', sendHeight);
  }, []);

  const [createUserPayload, setCreateUserPayload] = useState({});
  const [formStep2] = Form.useForm();

  /* Local states */
  const [currentStep, setCurrentStep] = useState<Step>(STEPS[0]);
  const [selectedState, selectState] = useState<string>('');

  const [notAvailableModal, setNotAvailableModal] = useState<boolean>(false);

  /* Out of service zone */
  const [outOfService, setOutOfService] = useState(false);

  /* Apis */
  const { registerEmployee, isLoading, isSuccess } = useEmployeeRegistration();

  const renderHeader = useCallback(() => {
    return (
      <div className="rounded-2xl justify-items-center items-center flex flex-col">
        {employerData?.jointLogo ? (
          <Image
            className="self-center justify-self-center max-w-sm"
            src={employerData.jointLogo}
            preview={false}
          />
        ) : null}
      </div>
    );
  }, [employerData, employerDataIsLoading]);
  const handleNextStep = useCallback(() => {
    /* Navigate to success page */
    setFormSubmitted(true);
  }, [setCurrentStep]);

  const handleSubmitForm = useCallback(() => {}, [
    createUserPayload,
    formStep2,
    employerCode,
  ]);

  useEffect(() => {
    if (isSuccess) {
      handleNextStep();
    }
  }, [handleNextStep, isSuccess]);

  const renderContent = useCallback(() => {
    if (formSubmitted) {
      return (
        <div className="items-center justify-center flex flex-1 flex-col">
          <Result status="success" title="Registration Completed " />
        </div>
      );
    }
    if (employerDataIsLoading) {
      return null;
    }
    return (
      <div className="rounded-2xl bg-white w-full p-6 ">
        <Space className="w-full" size={20} direction="vertical">
          <div className="text-center text-2xl">
            {
              'Bastion Health digital men’s health clinic | Nurse Patient Onboarding'
            }
          </div>
          <div className="">
            <NursePartnerSignupFormStep1 handleNextStep={handleNextStep} />
          </div>
        </Space>
      </div>
    );
  }, [currentStep, employerDataIsLoading, formSubmitted]);

  return (
    <div className="h-screen">
      <Layout className="px-12 pb-12">
        {renderHeader()}

        <Content>{renderContent()}</Content>
      </Layout>
      <NotAvailableStateModal
        selectedState={selectedState}
        visible={notAvailableModal}
        ailment=""
        onClose={() => {
          selectState('');
          setNotAvailableModal(false);
        }}
      />
    </div>
  );
};
