export enum FORM_FIELD_VALUES_ENUM {
  IS_DEPENDANT = 'isDependent',
  ON_BEHALF = 'onBehalf',
  EMAIL = 'email',
  PASSWORD = 'password',
  AGREEMENT = 'agreement',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  MIDDLE_NAME = 'middle-name',
  STATE = 'state',
  DOB = 'birthday',
  PHONE = 'phone',
  PACKAGE_NAME = 'packageIds',
  COUPON = 'coupon',
  ZIP = 'zip',
}
export type SignupFormBodyStep1 = {
  [FORM_FIELD_VALUES_ENUM.ON_BEHALF]: 'partner' | 'myself';
  [FORM_FIELD_VALUES_ENUM.IS_DEPENDANT]: boolean;
  [FORM_FIELD_VALUES_ENUM.EMAIL]: string;
  [FORM_FIELD_VALUES_ENUM.PASSWORD]: string;
  [FORM_FIELD_VALUES_ENUM.AGREEMENT]: boolean;
};

export type SignupFormBodyStep2 = {
  [FORM_FIELD_VALUES_ENUM.FIRSTNAME]: string;
  [FORM_FIELD_VALUES_ENUM.LASTNAME]: string;
  [FORM_FIELD_VALUES_ENUM.MIDDLE_NAME]: string;
  [FORM_FIELD_VALUES_ENUM.STATE]: string;
  [FORM_FIELD_VALUES_ENUM.DOB]: string;
  [FORM_FIELD_VALUES_ENUM.PHONE]: string;
  [FORM_FIELD_VALUES_ENUM.PACKAGE_NAME]: string[];
  [FORM_FIELD_VALUES_ENUM.COUPON]: string;
};
