import React, { useCallback } from 'react';
import { Result, Button } from 'antd';
import { Header } from 'pages/Payment/Header';
import { css } from '@emotion/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAllPackages } from '../../hooks/Api/stripe';
import { Package } from '../../types';

export type Product = {
  name: string;
  description: string;
  active: boolean;
  images: string[];
  metadata: {
    sku: string;
  };
};

export const AllProducts = () => {
  const { data, error, isLoading } = useGetAllPackages();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = location.search;
  const renderProductCard = useCallback(
    (item: Package) => {
      return (
        <div className="m-5 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <div className="p-5">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {item.name}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              {item.description}
            </p>
            <Button
              onClick={() => {
                navigate(
                  `/signup?packageId=${item.packageId}${queryParams?.replace(
                    '?',
                    '&',
                  )}`,
                );
              }}
            >
              Buy Now
            </Button>
          </div>
        </div>
      );
    },
    [navigate],
  );

  return (
    <div>
      <Header />
      <div className="flex flex-1   min-h-screen">
        <div className="flex flex-wrap justify-center">
          {data
            ?.filter((item: Package) => item.active)
            .map((product: Package) => renderProductCard(product))}
        </div>
      </div>
    </div>
  );
};
