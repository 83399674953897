import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { QueryNames } from './constants';
import { Department } from './types';
import apiCalls from './calls';

export function useGetAllDepartments() {
  const { data, isLoading, error, isSuccess } = useQuery(
    QueryNames.GetAllDepartments,
    () => {
      return apiCalls.getAllDepartments();
    },
    {
      onError: (e: Error) => {},
      onSettled: () => {},
      retry: 2,
    },
  );
  return {
    departments: data?.data?.data as Department[],
    isLoading,
    error: error as AxiosError,
    isSuccess,
  };
}
