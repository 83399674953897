import {
  Divider,
  Form,
  Input,
  Radio,
  Space,
  Button,
  Checkbox,
  Select,
  InputNumber,
  FormInstance,
  Tag,
} from 'antd';
import React, { useMemo, useCallback } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { FORM_FIELD_VALUES_ENUM } from '../types';
import { StateName } from '../../../utils/States';
import { useGetAllDepartments } from '../../../hooks/Api/department';
import { DateOfBirth } from '../components/DateOfBirth';
import { TwoStepPackageSelection } from '../components/TwoStepPackageSelection';
import { ConsentLink } from '../../ConsentLink';
import { InvoiceCard } from './InvoiceCard';

const { REACT_APP_ACTIVE_DEPARTMENTS } = process.env;

const PAYMENT_DEPARTMENT = REACT_APP_ACTIVE_DEPARTMENTS?.split(' ');
const { Option } = Select;
type Props = {
  form: FormInstance;
  isLoading: boolean;
  onStateSelect: (state: string) => void;
  setOutOfService: (state: boolean) => void;
  onSubmit: () => void;
  defaultSelectedPackages?: string[];
};

export const SignupFormStep2 = ({
  form,
  onStateSelect,
  isLoading,
  setOutOfService,
  onSubmit,
  defaultSelectedPackages = [],
}: Props) => {
  const packageName = Form.useWatch<string[]>(
    FORM_FIELD_VALUES_ENUM.PACKAGE_NAME,
    form,
  );

  const { departments } = useGetAllDepartments();
  const activeDepartments = useMemo(() => {
    return departments
      ?.filter(department => department.active)
      .map(department => department.state.toUpperCase());
  }, [departments]);

  const renderInvoice = useCallback(() => {
    if (isEmpty(packageName)) return null;
    return (
      <InvoiceCard
        onCouponApply={(couponCode: string | string[]) => {
          form.setFieldValue(FORM_FIELD_VALUES_ENUM.COUPON, couponCode);
        }}
        packageIds={packageName}
      />
    );
  }, [packageName, form]);

  const renderAilmentConsentCheckbox = useCallback(() => {
    if (isEmpty(packageName)) return null;
    return (
      <Form.Item
        name={FORM_FIELD_VALUES_ENUM.AGREEMENT}
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(
                    new Error(
                      'Please accept our testkit consent agreement. It is required to continue your care.',
                    ),
                  ),
          },
        ]}
      >
        <Checkbox>
          I accept{' '}
          <ConsentLink
            target="_blank"
            href={
              'https://www.getbastion.com/testing-informed-consent-all-tests'
            }
          >
            Test Kit Informed Consent
          </ConsentLink>
        </Checkbox>
      </Form.Item>
    );
  }, [packageName]);

  return (
    <Form
      wrapperCol={{ span: 24 }}
      size="large"
      layout="vertical"
      name="step2"
      key="step2-form"
      form={form}
      // defaultValue={formDefaultValues}
      onFinish={async v => {
        await form.validateFields();
        onSubmit();
      }}
    >
      <div className="grid grid-cols-1 gap-12 w-full md:grid-cols-2">
        <div className="w-full ">
          <Form.Item
            name={FORM_FIELD_VALUES_ENUM.FIRSTNAME}
            rules={[
              {
                required: true,
                message: 'Please fill in the firstname value.',
              },
            ]}
            label="Firstname"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={FORM_FIELD_VALUES_ENUM.MIDDLE_NAME}
            required={false}
            label="Middle name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={FORM_FIELD_VALUES_ENUM.LASTNAME}
            rules={[
              {
                required: true,
                message: 'Please fill in the lastname value.',
              },
            ]}
            label="Lastname"
          >
            <Input />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please select your state',
              },
            ]}
            label="State"
            name={FORM_FIELD_VALUES_ENUM.STATE}
            required
          >
            <Select
              showSearch
              placeholder="Select state"
              optionFilterProp="children"
              onSelect={(value: string) => {
                if (!activeDepartments?.includes(value.toUpperCase())) {
                  setOutOfService(true);
                }
                onStateSelect(value);
              }}
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              {Object.values(StateName).map(item => (
                <Option value={item.abbr}>{item.value}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            required
            rules={[
              {
                required: true,
                message: 'Your date of birth is not valid',
              },
            ]}
            name={FORM_FIELD_VALUES_ENUM.DOB}
            label="Date of birth"
          >
            <DateOfBirth form={form} />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message:
                  'Please add phone number. This is required for Bastion Care-Team to reach out to you.',
              },
              {
                validator: async (rule, value, callback) => {
                  if (!value || `${value}`.length !== 10) {
                    throw new Error('Phone number is incorrect!');
                  }
                },
              },
            ]}
            required
            name={FORM_FIELD_VALUES_ENUM.PHONE}
            label="Phone"
          >
            <InputNumber
              controls={false}
              minLength={9}
              placeholder="8602451640"
              addonBefore="+1"
              className="w-full "
            />
          </Form.Item>
        </div>
        <div>{renderInvoice()}</div>
      </div>
      <div className="col-span-2 w-full">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please select at least one of the packages above.',
            },
          ]}
          required
          name={FORM_FIELD_VALUES_ENUM.PACKAGE_NAME}
          label="Package Selection"
          wrapperCol={{ span: 24 }}
        >
          <TwoStepPackageSelection
            onSelect={(value: string[]) => {
              form.setFieldsValue({
                [FORM_FIELD_VALUES_ENUM.PACKAGE_NAME]: value,
              });
            }}
            defaultValue={defaultSelectedPackages}
          />
        </Form.Item>
      </div>
      {renderAilmentConsentCheckbox()}

      <Button
        // onClick={handleNextStep}
        htmlType="submit"
        loading={isLoading}
        icon={<ArrowRightOutlined />}
        type="primary"
      >
        Next <div className="inline text-xs text-opacity-10"> (To Payment)</div>
      </Button>
      <Form.Item noStyle name={FORM_FIELD_VALUES_ENUM.COUPON} />
    </Form>
  );
};
