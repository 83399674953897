import React, { useCallback, VFC } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { css, Global } from '@emotion/react';
import colors from 'constants/colors.json';
import { PaymentPage } from 'pages/Payment/PaymentPage';
// import 'antd/dist/antd.less';
import './tailwind.css';
import './app.css';

import { SuccessPage } from 'pages/Success/Success';
import { ConfigProvider } from 'antd';
import { AllProducts } from './pages/AllProducts/AllProducts';
import { EmployeeSignup } from './pages/EmployeeSignup/EmployeeSignup';
import { EmployerNotFound } from './pages/EmployerNotFound/EmployerNotFound';
import { NursePartnerSignup } from './pages/NursePartnerSignup/NursePartnerSignup';
import { PatientPartnerSignup } from './pages/PatientPartnerSignup/PatientPartnerSignup';
import { Signup } from './pages/Payment/Signup';

const queryClient = new QueryClient();

const { REACT_APP_APPLICATION_TYPE } = process.env;

const RedirectWithQuery = () => {
  const location = useLocation();
  const queryParams = location.search;

  return <Navigate to={`/all${queryParams}`} replace />;
};

export const App: VFC = () => {
  const renderRoutes = useCallback(() => {
    if (REACT_APP_APPLICATION_TYPE === 'employee-enrollment') {
      return (
        <>
          <Route
            path="/employee-signup/:employerCode"
            element={<EmployeeSignup />}
          />
          <Route path="/employer-not-found" element={<EmployerNotFound />} />
          <Route
            path="*"
            element={<Navigate to="/employer-not-found" replace />}
          />
        </>
      );
    }
    if (REACT_APP_APPLICATION_TYPE === 'partner-enrollment') {
      return (
        <>
          <Route
            path="/nurse-partner-signup/:employerCode"
            element={<NursePartnerSignup />}
          />
          <Route
            path="/patient-partner-signup/:employerCode"
            element={<PatientPartnerSignup />}
          />
          <Route path="/employer-not-found" element={<EmployerNotFound />} />
          <Route
            path="*"
            element={<Navigate to="/employer-not-found" replace />}
          />
        </>
      );
    }
    return (
      <>
        <Route path="/success/:productId" element={<SuccessPage />} />
        <Route path="/all" element={<AllProducts />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup" element={<RedirectWithQuery />} />
      </>
    );
  }, []);

  return (
    // Not used because antd uses deprecated findDOMNode in almost all components
    // Look here: https://github.com/ant-design/ant-design/issues/22493
    // <React.StrictMode>
    <>
      <Global
        styles={css`
          body {
            font-family: 'Yeseva One', cursive;
            background-color: white;
          }
        `}
      />
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#23435D',
              colorPrimaryBg: '#E4C18A',
              colorSuccess: '#E4C18A',
              fontFamily: 'Yeseva One',
            },
          }}
        >
          <BrowserRouter>
            <Routes>{renderRoutes()}</Routes>
          </BrowserRouter>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </ConfigProvider>
      </QueryClientProvider>
    </>
  );
};
