import { DateTime } from 'luxon';

export function formatDateToTime(date: DateTime): string {
  return date.toFormat('HH:mm');
}

export function isDateTomorrow(date: DateTime): boolean {
  return date.hasSame(DateTime.now().plus({ days: 1 }), 'day');
}

export function convertToIsoFormat(dateStr: string) {
  const dateArr = dateStr.split('-');
  const year = dateArr[2];
  const month = dateArr[0];
  const day = dateArr[1];
  return `${year}-${month}-${day}T00:00:00.000Z`;
}
