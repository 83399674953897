import {
  Divider,
  Form,
  Input,
  Radio,
  Space,
  Button,
  Checkbox,
  Select,
  InputNumber,
  Typography,
} from 'antd';
import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { ArrowRightOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { FORM_FIELD_VALUES_ENUM, SignupFormStep1 } from './types';
import { StateName } from '../../utils/States';
import { DateOfBirth } from '../EmployeeSignup/components/DateOfBirth';
import { AilmentCards } from './components/AilmentCards';
import { FertilityQuestionnaireForm } from './components/Questionnaires/FertilityQuestionnaireForm';
import {
  PACKAGE_UNIQUE_CODE,
  PackageUniqueCode,
} from '../../services/package.static';
import { NurseOnboardingQuestionnaire } from './components/NuresQuestionnair';

const { Title, Paragraph, Text, Link } = Typography;

export const FormLabel = styled.p`
  font-size: 24px;
`;

type Props = {
  handleNextStep: (data: SignupFormStep1) => void;
};

export const NursePartnerSignupFormStep1 = ({ handleNextStep }: Props) => {
  const [formStep1] = Form.useForm<SignupFormStep1>();
  const formWatchBehalf = Form.useWatch<string>(
    FORM_FIELD_VALUES_ENUM.IS_DEPENDANT,
    formStep1,
  );
  const selectedPackages = Form.useWatch<PackageUniqueCode[]>(
    FORM_FIELD_VALUES_ENUM.PACKAGE_NAME,
    formStep1,
  );

  const renderStep1AdditionalInfo = useCallback(() => {
    if (formWatchBehalf) {
      return (
        <div
          style={{ backgroundColor: '#F3CF98' }}
          className="mt-5 p-6 rounded-xl shadow-lg mb-10"
        >
          <div>
            Remember to only provide the information of the person who will take
            part in the program. Including their name, email, and date of birth.
          </div>
        </div>
      );
    }
    return null;
  }, [formWatchBehalf]);

  const renderPackageQuestionnaire = useCallback(() => {
    return selectedPackages?.map(selectedPackage => {
      return (
        <FertilityQuestionnaireForm
          questions={
            NurseOnboardingQuestionnaire[selectedPackage].questionnaire
          }
          sectionTitle={NurseOnboardingQuestionnaire[selectedPackage].title}
        />
      );
    });
  }, [selectedPackages]);

  return (
    <>
      <Form
        wrapperCol={{ span: 12 }}
        size="large"
        name="step1"
        layout="vertical"
        key="step1-form"
        form={formStep1}
        onFinish={async v => {
          await formStep1.validateFields();
          handleNextStep(formStep1.getFieldsValue());
        }}
      >
        <Form.Item
          name={FORM_FIELD_VALUES_ENUM.IS_DEPENDANT}
          label={<FormLabel>Patient is applying for</FormLabel>}
          valuePropName="value"
          rules={[
            {
              required: true,
              message:
                'Please tell us is it for the employee themselves or their dependant.',
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={false}>Themselves</Radio>
              <Radio value>A partner or dependent</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {renderStep1AdditionalInfo()}

        <Divider />
        <Form.Item
          rules={[{ type: 'email' }]}
          name={FORM_FIELD_VALUES_ENUM.EMAIL}
          required
          label="Email Address"
        >
          <Input
            css={css`
              border: 1px solid rgb(212, 212, 212);
            `}
          />
        </Form.Item>

        <Form.Item
          name={FORM_FIELD_VALUES_ENUM.FIRSTNAME}
          rules={[
            {
              required: true,
              message: 'Please fill in the firstname value.',
            },
          ]}
          label="Firstname"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={FORM_FIELD_VALUES_ENUM.MIDDLE_NAME}
          required={false}
          label="Middle name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={FORM_FIELD_VALUES_ENUM.LASTNAME}
          rules={[
            {
              required: true,
              message: 'Please fill in the lastname value.',
            },
          ]}
          label="Lastname"
        >
          <Input />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please select your state',
            },
          ]}
          label="State"
          name={FORM_FIELD_VALUES_ENUM.STATE}
          required
        >
          <Select
            showSearch
            placeholder="Select state"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.values(StateName).map(item => (
              <Select.Option value={item.abbr} key={item.value}>
                {item.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          required
          rules={[
            {
              required: true,
              message: 'Your date of birth is not valid',
            },
          ]}
          name={FORM_FIELD_VALUES_ENUM.DOB}
          label="Date of birth"
        >
          <DateOfBirth form={formStep1} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message:
                'Please add phone number. This is required for Bastion Care-Team to reach out to you.',
            },
            {
              validator: async (rule, value, callback) => {
                if (!value || `${value}`.length !== 10) {
                  throw new Error('Phone number is incorrect!');
                }
              },
            },
          ]}
          required
          name={FORM_FIELD_VALUES_ENUM.PHONE}
          label="Phone"
        >
          <InputNumber
            controls={false}
            minLength={9}
            placeholder="8602451640"
            addonBefore="+1"
            className="w-full "
          />
        </Form.Item>
        <Paragraph className="p-0">
          <blockquote
            css={css`
              font-family: Roboto;
              font-size: 16px;
            `}
          >
            Bastion Health is dedicated to providing comprehensive care for
            men’s health.
            <br />
            Please choose the package that best fits your needs, and we’ll
            ensure you receive the care you deserve.
          </blockquote>
        </Paragraph>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please select at least one of the packages above.',
            },
          ]}
          required
          name={FORM_FIELD_VALUES_ENUM.PACKAGE_NAME}
          label="Men's Health Packages"
          wrapperCol={{ span: 24 }}
        >
          <AilmentCards
            onSelect={(value: string[]) => {
              formStep1.setFieldsValue({
                [FORM_FIELD_VALUES_ENUM.PACKAGE_NAME]: value,
              });
            }}
          />
        </Form.Item>
        {renderPackageQuestionnaire()}

        <Button
          // onClick={handleNextStep}
          htmlType="submit"
          icon={<ArrowRightOutlined />}
          type="primary"
        >
          Create Account
        </Button>
      </Form>
    </>
  );
};
