import { useMutation, useQuery } from 'react-query';
import { omitBy } from 'lodash';
import { AxiosError } from 'axios';
import { QueryNames } from './constants';
import { EmployerPublicDetails, RegisterEmployeeParams } from './types';
import apiCalls from './calls';

export function useEmployeeRegistration() {
  const { data, isLoading, error, mutateAsync, isSuccess } = useMutation(
    QueryNames.RegisterEmployee,
    (body: RegisterEmployeeParams) => {
      return apiCalls.registerEmployee(
        omitBy(body, item => !item) as RegisterEmployeeParams,
      );
    },
    {
      onError: (e: Error) => {},
      onSettled: () => {},
      retry: 1,
    },
  );
  return {
    data: data?.data?.data as any,
    isLoading,
    error: error as AxiosError,
    registerEmployee: mutateAsync,
    isSuccess,
  };
}

export function useEmployeePublicDetails(employerCode: string) {
  const { data, isLoading, error, isSuccess } = useQuery(
    QueryNames.RegisterEmployee,
    () => {
      return apiCalls.getEmployerPublicDetails(employerCode);
    },
    {
      onError: (e: Error) => {},
      onSettled: () => {},
      retry: 2,
    },
  );
  return {
    data: data?.data?.data as EmployerPublicDetails,
    isLoading,
    error: error as AxiosError,
    isSuccess,
  };
}
